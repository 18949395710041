import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Paper,
  Switch,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FileCopyOutlined as CopyIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";
import { useDebounce } from "react-use";

import { CreativeListTableCard_project } from "~/__relay_artifacts__/CreativeListTableCard_project.graphql";
import { DialogButton } from "~/components//DialogButton";
import { CreativeAdmPreviewButtons } from "~/components/CreativeAdmPreviewButtons";
import { CreativeSdkVersionLinks } from "~/components/CreativeSdkVersionLinks";
import { ListTable } from "~/components/ListTable";
import { Period } from "~/components/Period";
import { UpdatedAtText } from "~/components/UpdatedAtText";
import { TableCell } from "~/components/atoms/TableCell";
import { CreativeCopyForm } from "~/containers/CreativeCopyForm";
import { CreativeCreateForm } from "~/containers/CreativeCreateForm";
import { CreativeEditForm } from "~/containers/CreativeEditForm";
import { CreativeNoteEditForm } from "~/containers/CreativeNoteEditForm";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { useUpdateCreativeMutation } from "~/mutations/UpdateCreativeMutation";

import { ArchiveButton } from "./ArchiveButton";
import { ArchiveConfirmationModal } from "./ArchiveConfirmationModal";
import { TagListField } from "./TagListField";

type Props = {
  relay: RelayPaginationProp;
  project: CreativeListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const CreativeListTableCard: FC<Props> = ({
  relay,
  project: { creatives },
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [withArchived, setWithArchived] = useState(false);
  const [searchTitle, setSearchTitle] = useState<string | null>(null);
  const [isBulkArchiving, setIsBulkArchiving] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const totalCount = creatives.totalCount;
  const { enqueueSnackbar } = useSnackbar();
  const { updateCreativeMutation } = useUpdateCreativeMutation();

  const creativesNodes = useMemo(() => {
    const edges = creatives?.edges || [];
    return edges.slice(page * perPage, page * perPage + perPage).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [creatives, page, perPage]);

  const getSelectionState = useCallback(() => {
    const nonArchivedCreatives = creativesNodes.filter((c) => !c.archived);

    return {
      isIndeterminate:
        selectedIds.length > 0 &&
        selectedIds.length < nonArchivedCreatives.length,
      isAllSelected:
        nonArchivedCreatives.length > 0 &&
        nonArchivedCreatives.every((c) => selectedIds.includes(c.id)),
    };
  }, [creativesNodes, selectedIds]);

  const handleSelect = useCallback(
    (id: string) => {
      const next = selectedIds.includes(id)
        ? selectedIds.filter((s) => s !== id)
        : [...new Set([...selectedIds, id])];
      setSelectedIds(next);
    },
    [selectedIds]
  );

  const handleSelectAll = useCallback(() => {
    // If all non-archived creatives are selected, deselect all
    const selectableCreatives = creativesNodes.filter(
      (creative) => !creative.archived
    );
    const allSelected = selectableCreatives.every((creative) =>
      selectedIds.includes(creative.id)
    );

    if (allSelected) {
      setSelectedIds([]);
    } else {
      // Select all non-archived creatives
      const newSelectedIds = selectableCreatives.map((creative) => creative.id);
      setSelectedIds(newSelectedIds);
    }
  }, [creativesNodes, selectedIds]);

  const handleRefreshCreativeList = useCallback(() => {
    relay.refetchConnection(perPage, noop, {
      withArchived,
      title: searchTitle,
    });
  }, [perPage, relay, searchTitle, withArchived]);

  const handleWithArchivedChange = useCallback(() => {
    setWithArchived(!withArchived);
    relay.refetchConnection(perPage, noop, {
      withArchived: !withArchived,
      title: searchTitle,
    });
  }, [perPage, relay, searchTitle, withArchived]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    const value = e.target.value;
    setSearchTitle(value || null);
  }, []);

  const handleOpenArchiveModal = useCallback(() => {
    if (selectedIds.length === 0) {
      enqueueSnackbar("アーカイブするクリエイティブを選択してください。", {
        variant: "warning",
      });
      return;
    }
    setIsArchiveModalOpen(true);
  }, [selectedIds.length, enqueueSnackbar]);

  const handleCloseArchiveModal = useCallback(() => {
    setIsArchiveModalOpen(false);
  }, []);

  const handleBulkArchive = useCallback(async () => {
    setIsBulkArchiving(true);

    try {
      // Find the selected creative nodes to get their latestVersion
      const selectedCreatives = creativesNodes.filter(
        (creative) => selectedIds.includes(creative.id) && !creative.archived
      );

      // Archive each selected creative
      const archivePromises = selectedCreatives.map((creative) =>
        updateCreativeMutation({
          id: creative.id,
          viewableValidationEnabled: false,
          archived: true,
          clientVersion: creative.latestVersion,
        })
      );

      await Promise.all(archivePromises);

      // Clear selection and refresh the list
      setSelectedIds([]);
      handleRefreshCreativeList();

      enqueueSnackbar(
        `${selectedCreatives.length}件のクリエイティブをアーカイブしました。`,
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar("一括アーカイブに失敗しました。", { variant: "error" });
    } finally {
      setIsBulkArchiving(false);
      setIsArchiveModalOpen(false);
    }
  }, [
    selectedIds,
    creativesNodes,
    updateCreativeMutation,
    enqueueSnackbar,
    handleRefreshCreativeList,
  ]);

  // Get selected creatives for the modal
  const selectedCreatives = useMemo(() => {
    return creativesNodes
      .filter(
        (creative) => selectedIds.includes(creative.id) && !creative.archived
      )
      .map((creative) => ({
        id: creative.id,
        title: creative.title,
        createdAt: creative.createdAt,
        updatedAt: creative.updatedAt,
        periodSince: creative.periodSince,
        periodTill: creative.periodTill,
      }));
  }, [creativesNodes, selectedIds]);

  useDebounce(handleRefreshCreativeList, 500, [searchTitle]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1">クリエイティブ一覧</Typography>
        <DialogButton
          title="クリエイティブ作成"
          render={({ close }) => (
            <CreativeCreateForm
              onSubmitCompleted={() => {
                handleRefreshCreativeList();
                close();
              }}
            />
          )}
        >
          <Icon>add</Icon>
          新規作成
        </DialogButton>
        <FormControlLabel
          label="アーカイブ済みデータを含める"
          control={
            <Switch
              checked={withArchived}
              onChange={handleWithArchivedChange}
            />
          }
        />
        <div style={{ marginLeft: 8 }}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={selectedIds.length === 0 || isBulkArchiving}
            onClick={handleOpenArchiveModal}
          >
            {isBulkArchiving ? "処理中..." : "選択項目をアーカイブ"}
          </Button>
        </div>
      </Toolbar>
      <Toolbar>
        <TextField
          fullWidth
          label="タイトルで絞り込む"
          value={searchTitle}
          onChange={handleTextFieldChange}
        />
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={getSelectionState().isIndeterminate}
                checked={getSelectionState().isAllSelected}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>タイトル</TableCell>
            <TableCell>タグ</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>ADMバージョン</TableCell>
            <TableCell>SDKバージョン</TableCell>
            <TableCell>OS</TableCell>
            <TableCell>W</TableCell>
            <TableCell>H</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>更新日</TableCell>
            <TableCell>配信期間</TableCell>
            <TableCell>プレビュー</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {creativesNodes.map((creative) => (
            <TableRow
              selected={selectedIds.includes(creative.id)}
              key={creative.id}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedIds.includes(creative.id)}
                  onChange={() => handleSelect(creative.id)}
                />
              </TableCell>
              <TableCell>
                <Link to={`creatives/${creative.id}`}>{creative.title}</Link>
              </TableCell>
              <TableCell>
                <TagListField creative={creative} />
              </TableCell>
              <TableCell>{atob(creative.id)}</TableCell>
              <TableCell>{creative.metaAdmVersion || "-"}</TableCell>
              <TableCell>
                <CreativeSdkVersionLinks sdkVersion={creative.sdkVersion} />
              </TableCell>
              <TableCell>{creative.metaOs || "-"}</TableCell>
              <TableCell>{creative.width || "-"}</TableCell>
              <TableCell>{creative.height || "-"}</TableCell>
              <TableCell>{creative.metaPosition || "-"}</TableCell>
              <TableCell>{parseISO(creative.createdAt)}</TableCell>
              <TableCell>
                <UpdatedAtText updatedAtISOString={creative.updatedAt} />
              </TableCell>
              <TableCell>
                <Period
                  since={creative.periodSince}
                  till={creative.periodTill}
                />
              </TableCell>
              <TableCell>
                <CreativeAdmPreviewButtons
                  adm={creative.adm}
                  height={creative.height}
                  width={creative.width}
                />
              </TableCell>
              <TableCell>
                <DialogButton
                  title="クリエイティブ(WebComponent版)の編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <CreativeEditForm
                      creativeRef={creative}
                      onSubmitCompleted={() => {
                        relay.refetchConnection(perPage, noop, {
                          withArchived,
                        });
                        close();
                      }}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <DialogButton
                  title="メモ入力"
                  minWidth={600}
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <CreativeNoteEditForm
                      creativeRef={creative}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  メモ入力
                </DialogButton>
                <DialogButton
                  title="クリエイティブコピー"
                  render={({ close }) => (
                    <CreativeCopyForm
                      creativeId={creative.id}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  <Tooltip title="コピーする">
                    <CopyIcon />
                  </Tooltip>
                </DialogButton>
                <ArchiveButton
                  creative={creative}
                  onSubmitCompleted={handleRefreshCreativeList}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />

      <ArchiveConfirmationModal
        open={isArchiveModalOpen}
        onClose={handleCloseArchiveModal}
        onConfirm={handleBulkArchive}
        creatives={selectedCreatives}
        isArchiving={isBulkArchiving}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  CreativeListTableCard,
  {
    project: graphql`
      fragment CreativeListTableCard_project on Project {
        id
        creatives(
          first: $first
          after: $after
          withArchived: $withArchived
          title: $title
        ) @connection(key: "CreativeListTableCard_creatives") {
          totalCount
          edges {
            node {
              id
              title
              adm
              height
              width
              createdAt
              updatedAt
              sdkVersion
              periodSince
              periodTill
              metaOs
              metaPosition
              metaAdmVersion
              archived
              note
              latestVersion
              tagList
              ...CreativeEditForm_creative
              ...CreativeNoteEditForm_creative
            }
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps: (props) => props.project.creatives as any,
    getVariables: (props, { count, cursor }) => ({
      projectId: props.project.id,
      first: count,
      after: cursor,
    }),
    query: graphql`
      query CreativeListTableCard_Query(
        $projectId: ID!
        $first: Int
        $after: String
        $withArchived: Boolean
        $title: String
      ) {
        project(id: $projectId) {
          ...CreativeListTableCard_project
        }
      }
    `,
  }
);
