import { Grid, IconButton } from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  FormikDateTimeField,
  FormikMultipleSelectField,
  FormikNumberField,
  FormikSelectField,
  FormikSwitchField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type Category =
  | "VIDEO_COMPUTER_GAMES"
  | "COMIC_BOOKS"
  | "DRAWING_SKETCHING";

export type FormValues = {
  title: string;
  categories?: [Category];
  viewTrackingUrl: string | null;
  clickTrackingUrl: string | null;
  fee: number;
  feeMin: number;
  adomain?: string | null;
  status: "PENDING" | "STOPPED" | "ACTIVE";
  bidStrategy: "MANUAL" | "ML";
  reportingOption: "CPM" | "CPI" | "SIMEJI";
  note: string | null;
  periodSince?: string;
  periodTill: string | null;
  frequencyCapEnabled: boolean;
  frequencyCapAmount?: number;
  frequencyCapInterval?:
    | "DAY"
    | "FIFTEEN_MINUTES"
    | "FIVE_MINUTES"
    | "HOUR"
    | "MINUTE"
    | "MONTH"
    | "NONE"
    | "SIX_HOURS"
    | "TWELVE_HOURS"
    | "TWO_HOURS"
    | "WEEK"
    | "YEAR";
  goalAmount: number;
  os?: "IOS" | "ANDROID";
  osVersionMin: string;
  isBanditEnabled: boolean;
  isAutoRefreshPlacementsEnabled: boolean;
  isPacingEnabled: boolean;
  isPricingExplorationEnabled: boolean;
  isCarryoverEnabled: boolean;
  monthlyBudget: number;
  dailyBudget: number;
  costCpi?: number | null;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  title: yup.string().required(),
  categories: yup
    .array()
    .of(
      yup
        .string()
        .oneOf(["VIDEO_COMPUTER_GAMES", "COMIC_BOOKS", "DRAWING_SKETCHING"])
    )
    .required(),
  clickthroughTrackingUrlAndroid: yup.string().nullable(),
  clickthroughTrackingUrlIos: yup.string().nullable(),
  viewTrackingUrl: yup.string().nullable(),
  clickTrackingUrl: yup.string().nullable(),
  feeMin: yup.number(),
  adomain: yup.string().required(),
  status: yup.string().oneOf(["PENDING", "STOPPED", "ACTIVE"]).required(),
  bidStrategy: yup.string().oneOf(["MANUAL", "ML"]).required(),
  reportingOption: yup.string().oneOf(["CPM", "CPI", "SIMEJI"]).required(),
  note: yup.string().nullable(),
  periodSince: yup.string().required(),
  periodTill: yup.string().nullable(),
  frequencyCapEnabled: yup.boolean().required(),
  frequencyCapAmount: yup.number().when("frequencyCapEnabled", {
    is: true,
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
  frequencyCapInterval: yup.string().when("frequencyCapEnabled", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  goalAmount: yup.number().required(),
  os: yup.string().oneOf(["IOS", "ANDROID"]).required(),
  osVersionMin: yup
    .string()
    .matches(
      /^[0-9]+\.[0-9]+\.[0-9]+$/,
      "MAJOR.MINOR.PATCHの形式で入力してください"
    )
    .required(),
  isBanditEnabled: yup.boolean().required(),
  isAutoRefreshPlacementsEnabled: yup.boolean().required(),
  isPacingEnabled: yup.boolean().required(),
  isPricingExplorationEnabled: yup.boolean().required(),
  isCarryoverEnabled: yup.boolean().required(),
  monthlyBudget: yup.number().required(),
  dailyBudget: yup.number().required(),
  // costCpi: yup.number().nullable(),
  costCpi: yup.number().when("reportingOption", {
    is: "SIMEJI",
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
  fee: yup.number().default(1.3).required(),
});

const categoryOptions = [
  { value: "", label: "未選択" },
  {
    value: "VIDEO_COMPUTER_GAMES",
    label: "VIDEO_COMPUTER_GAMES",
  },
  { value: "COMIC_BOOKS", label: "COMIC_BOOKS" },
  { value: "DRAWING_SKETCHING", label: "DRAWING_SKETCHING" },
];

const reportingOptionOptions = [
  { value: "CPM", label: "CPM" },
  { value: "CPI", label: "CPI" },
  { value: "SIMEJI", label: "SIMEJI" },
];

const frequencyCapIntervalOptions = [
  { value: "", label: "未選択" },
  { value: "MINUTE", label: "分毎" },
  { value: "FIVE_MINUTES", label: "5分毎" },
  { value: "FIFTEEN_MINUTES", label: "15分毎" },
  { value: "HOUR", label: "時間毎" },
  { value: "TWO_HOURS", label: "2時間毎" },
  { value: "SIX_HOURS", label: "6時間毎" },
  { value: "TWELVE_HOURS", label: "12時間毎" },
  { value: "DAY", label: "日毎" },
];

const osOptions = [
  { value: "", label: "未選択" },
  { value: "IOS", label: "IOS" },
  { value: "ANDROID", label: "ANDROID" },
];

const bidStrategy = [
  { value: "MANUAL", label: "手動" },
  { value: "ML", label: "ML" },
];

const useStyles = makeStyles(() => ({
  clearButton: {
    padding: "0",
  },
}));

export const CampaignForm: React.FC<Props> = ({ values, setFieldValue }) => {
  const classes = useStyles();
  const handleOsOptions = React.useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setFieldValue("os", e.target.value);
      if (e.target.value === "IOS") {
        setFieldValue("osVersionMin", "14.2.0");
      }
      if (e.target.value === "ANDROID") {
        setFieldValue("osVersionMin", "9.0.0");
      }
    },
    [setFieldValue]
  );

  const handleClearPeriodTill = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setFieldValue("periodTill", null);
    },
    [setFieldValue]
  );

  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikTextField
            required
            fullWidth
            name="title"
            label="キャンペーン名"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikMultipleSelectField
            required
            fullWidth
            name="categories"
            label="カテゴリ"
            options={categoryOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSelectField
            required
            fullWidth
            name="os"
            label="対象のOS"
            options={osOptions}
            onChange={handleOsOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            fullWidth
            name="osVersionMin"
            label="OSの最低バージョン(MAJOR.MINOR.PATCH)"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            fullWidth
            name="adomain"
            label="ドメイン"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSelectField
            fullWidth
            required
            name="bidStrategy"
            label="入札戦略"
            options={bidStrategy}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSelectField
            fullWidth
            required
            name="reportingOption"
            label="レポートオプション"
            options={reportingOptionOptions}
          />
        </Grid>
        {values.reportingOption === "SIMEJI" && (
          <Grid item xs={12}>
            <FormikNumberField
              required
              fullWidth
              name="costCpi"
              label="原価CPI"
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormikNumberField
            required
            fullWidth
            name="monthlyBudget"
            label="月予算"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikNumberField
            required
            fullWidth
            name="dailyBudget"
            label="日予算"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateTimeField
            required
            fullWidth
            name="periodSince"
            label="開始日"
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateTimeField
            fullWidth
            name="periodTill"
            label="終了日"
            minDate={values.periodSince ? values.periodSince : new Date()}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={classes.clearButton}
                  onClick={handleClearPeriodTill}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikNumberField
            fullWidth
            name="feeMin"
            label="最低手数料率"
            margin="normal"
            precision={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikNumberField
            fullWidth
            name="goalAmount"
            label="目標CPI"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField fullWidth name="note" label="メモ" margin="normal" />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            fullWidth
            multiline
            rowsMax={5}
            name="viewTrackingUrl"
            label="ViewThroughトラッキングURL"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            fullWidth
            multiline
            rowsMax={5}
            name="clickTrackingUrl"
            label="ClickThroughトラッキングURL"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="isCarryoverEnabled"
            label="予算の繰越しを有効にする"
            initialValue={values.isCarryoverEnabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="frequencyCapEnabled"
            label="フリークエンシーキャップを有効にする"
            initialValue={values.frequencyCapEnabled}
          />
        </Grid>
        {values.frequencyCapEnabled && (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} md={4}>
              <FormikSelectField
                fullWidth
                name="frequencyCapInterval"
                label="期間"
                options={frequencyCapIntervalOptions}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormikNumberField
                fullWidth
                name="frequencyCapAmount"
                label="回数"
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormikSwitchField
            name="isPacingEnabled"
            label="ペーシングを有効にする"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="isPricingExplorationEnabled"
            label="プライシングの探索機能を有効にする"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="isBanditEnabled"
            label="クリエイティブのバンディットテストを有効にする"
            initialValue={values.isBanditEnabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="isAutoRefreshPlacementsEnabled"
            label="プレイスメントの自動更新をONにする"
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
};
