import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteAdCreativeMutation } from "~/__relay_artifacts__/DeleteAdCreativeMutation.graphql";
import { ConnectionUpdaterOptions } from "~/lib/mutationUtils";

const mutation = graphql`
  mutation DeleteAdCreativeMutation($input: DeleteAdCreativeInput!) {
    deleteAdCreative(input: $input) {
      deletedCreativeId
      creative {
        id
        title
      }
    }
  }
`;

type InputParam = {
  environment: Environment;
  adId: string;
  creativeId: string;
  options: ConnectionUpdaterOptions;
};

const commit = ({ environment, adId, creativeId, options }: InputParam) =>
  commitMutation<DeleteAdCreativeMutation>(environment, {
    mutation,
    variables: {
      input: {
        adId: adId,
        creativeId: creativeId,
      },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: options.parentId,
        connectionKeys: [
          {
            key: options.connectionName,
          },
        ],
        pathToConnection: ["ad", "creatives"],
        deletedIDFieldName: "deletedCreativeId",
      },
    ],
    updater: (store) => {
      const payload = store.getRootField("deleteAdCreative");
      if (!payload) {
        return;
      }

      const creative = payload.getLinkedRecord("creative");
      if (!creative || !creative.getDataID()) {
        return;
      }

      const parentRecord = store.get(adId);
      if (!parentRecord) {
        return;
      }

      const connectionName = "DeleteAdCreativeMutation_selectableCreatives";
      const connection = parentRecord.getOrCreateLinkedRecord(
        connectionName,
        "ConnectionRecord"
      );

      const edges = connection.getLinkedRecords("edges") || [];

      const newEdge = store.create(
        `edge:${creative.getDataID()}`,
        "CreativeEdge"
      );
      newEdge.setLinkedRecord(creative, "node");

      connection.setLinkedRecords([...edges, newEdge], "edges");
    },
  });

export default commit;
