import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { FC } from "react";

import { Period } from "~/components/Period";
import { UpdatedAtText } from "~/components/UpdatedAtText";
import { parseISO } from "~/lib/parseISO";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    minWidth: "600px",
  },
  dialogTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  table: {
    border: `1px solid ${theme.palette.divider}`,
  },
  tableHead: {
    backgroundColor: theme.palette.grey[100],
  },
  tableCell: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: "center",
  },
}));

type Creative = {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  periodSince: string | null;
  periodTill: string | null;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  creatives: Creative[];
  isArchiving: boolean;
};

export const ArchiveConfirmationModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  creatives,
  isArchiving,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="archive-confirmation-dialog-title"
    >
      <DialogTitle
        id="archive-confirmation-dialog-title"
        className={classes.dialogTitle}
      >
        <Typography variant="h6">
          これらのクリエイティブをアーカイブしますか？
          <br />
          アーカイブ後はもとに戻せません。
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Paper elevation={0} className={classes.table}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell}>タイトル</TableCell>
                <TableCell className={classes.tableCell}>作成日</TableCell>
                <TableCell className={classes.tableCell}>更新日</TableCell>
                <TableCell className={classes.tableCell}>配信期間</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creatives.map((creative) => (
                <TableRow key={creative.id}>
                  <TableCell className={classes.tableCell}>
                    {creative.title}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {parseISO(creative.createdAt)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <UpdatedAtText updatedAtISOString={creative.updatedAt} />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Period
                      since={creative.periodSince}
                      till={creative.periodTill}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="primary" variant="outlined">
          キャンセル
        </Button>
        <Button
          onClick={onConfirm}
          color="secondary"
          variant="contained"
          disabled={isArchiving}
          autoFocus
        >
          {isArchiving ? "処理中..." : "アーカイブする"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
