import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

export type ConnectionUpdaterOptions = {
  parentId: string;
  connectionName: string;
};

export const sharedDeleteUpdater = (
  itemId: string,
  store: RecordSourceSelectorProxy,
  { parentId, connectionName }: ConnectionUpdaterOptions
) => {
  const proxy = store.get(parentId)!;
  const conn = ConnectionHandler.getConnection(proxy, connectionName);
  if (!conn) return;
  ConnectionHandler.deleteNode(conn, itemId);
};
